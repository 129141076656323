import * as actionTypes from '../actions/actions';

const initialState = {
	system: {},
	ui: {
		theme: 'dark',
		loading: false,
	},
	sidebar: {
		collapsed: false,
	},
	auth: {
		isAuthenticated: false,
		token: '',
		user: {},
	},
	list: {
		advertisementStatus: [],
		category: [],
	},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		// System

		case 'INITIALIZE_APP':
			return {
				...state,
				system: {
					...state.system,
					appName: action.systemSettings.appName,
					appVersion: action.systemSettings.appVersion,
					filesPath: action.systemSettings.filesPath,
					pageDetails: action.pageDetails,
				},
				list: {
					...state.list,
					advertisementStatus: action.advertisementStatus,
					category: action.category,
				},
			};

		// UI

		case 'SET_LOADING':
			return {
				...state,
				ui: {
					...state.ui,
					loading: action.params,
				},
			};

		// Sidebar

		case 'SET_SIDEBAR_STATE':
			return {
				...state,
				sidebar: {
					...state.sidebar,
					collapsed: action.params.collapsed,
				},
			};

		// Auth

		case 'SET_USER':
			return {
				...state,
				auth: {
					...state.auth,
					isAuthenticated: true,
					user: action.params.user,
					token: action.token,
				},
			};

		case 'LOGIN':
			return {
				...state,
				auth: {
					...state.auth,
					isAuthenticated: true,
					user: action.params.user,
					token: action.token,
				},
			};

		case 'LOGOUT':
			return {
				...state,
				auth: {
					...state.auth,
					isAuthenticated: false,
				},
			};
	}
	return state;
};

export default reducer;
