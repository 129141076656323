import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import './App.less';
import appData from '../../../package.json';

import Guard from '../Guard/Guard';
import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Sidebar from '../../containers/Sidebar/Sidebar';
import Header from '../../containers/Header/Header';
import Advertisements from '../../pages/Advertisements/Advertisements';
import Advertisement from '../../pages/Advertisements/Advertisement/Advertisement';
import Customers from "../../pages/Customers/Customers";
import Customer from "../../pages/Customers/Customer/Customer";
import Categories from '../../pages/Categories/Categories';
import Category from '../../pages/Categories/Category/Category';
import Dashboard from "../../pages/Dashboard/Dashboard";

import Forbidden from '../../pages/Auth/Forbidden/Forbidden';

import { Layout, Typography } from 'antd';

const { Content, Footer } = Layout;
const { Text } = Typography;

const App = (props) => {
	const cssClasses = [styles.App];
	const routeProps = {
		store: props.store,
	};

	return (
		<div className={cssClasses.join(' ')}>
			<Layout className={styles.siteContainer}>
				<Guard store={props.store}>
					<PageLoader store={props.store} />
					<Sidebar store={props.store} />
					<Layout className={props.store.sidebar.collapsed ? styles.siteLayoutSmallMargin : styles.siteLayoutLargeMargin}>
						<Header className={styles.header} store={props.store} />
						<Content className={styles.content}>
							<ErrorBoundary>
								<Switch>
									<Route exact path='/'>
										{props.store.auth.isAuthenticated ? <Advertisements {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/advertisements'>
										{props.store.auth.isAuthenticated ? <Advertisements {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/advertisement/:key'>
										{props.store.auth.isAuthenticated ? <Advertisement {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customers'>
										{props.store.auth.isAuthenticated ? <Customers {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customer/:key'>
										{props.store.auth.isAuthenticated ? <Customer {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/categories'>
										{props.store.auth.isAuthenticated ? <Categories {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/category/:id'>
										{props.store.auth.isAuthenticated ? <Category {...routeProps} /> : <Redirect to='/login' />}
									</Route>
								</Switch>
							</ErrorBoundary>
						</Content>
						<Footer className={styles.footer} store={props.store}>
							<Text type='secondary'>
								{props.store.system.appName} - v{appData.version} - {process.env.NODE_ENV}
							</Text>
						</Footer>
					</Layout>
				</Guard>
			</Layout>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
	};
};

export default connect(mapStateToProps)(App);
