import server from '../../utils/axios/api';
import serverAuth from '../../utils/axios/auth';
import errorHandler from '../../utils/error-handler';

/**
-----------------------------------
* - Action names
-----------------------------------
*/

export const INITIALIZE_APP = 'INITIALIZE_APP';
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';

/**
-----------------------------------
* - Action creators
-----------------------------------
*/

// System

export const initializeApp = (params) => {
	return (dispatch) => {
		let systemSettings;
		let pageDetails;
		let advertisementStatus;
		let category;
		server
			.get('/settings')
			.then((response) => {
				systemSettings = response.data;
				server
					.get('/page-settings')
					.then((response) => {
						pageDetails = response.data;
						server
							.get('/advertisement-statuses')
							.then((response) => {
								advertisementStatus = response.data;
								server
									.get('/list/categories')
									.then((response) => {
										category = response.data;
										dispatch(finalizeInitializeApp(systemSettings, pageDetails, advertisementStatus, category));
									})
									.catch((error) => {
										errorHandler(error);
									});
							})
							.catch((error) => {
								errorHandler(error);
							});
					})
					.catch((error) => {
						errorHandler(error);
					});
			})
			.catch((error) => {});
	};
};

export const finalizeInitializeApp = (systemSettings, pageDetails, advertisementStatus, category) => {
	return {
		type: INITIALIZE_APP,
		systemSettings,
		pageDetails,
		advertisementStatus,
		category
	};
};

// UI

export const setLoading = (params) => {
	return {
		type: SET_LOADING,
		params: params,
	};
};

// Sidebar

export const setSidebarState = (params) => {
	return {
		type: SET_SIDEBAR_STATE,
		params: params,
	};
};

// Auth

export const setUser = (params, token) => {
	return {
		type: SET_USER,
		params: params,
		token: token,
	};
};

export const login = (params, token) => {
	return {
		type: LOGIN,
		params: params,
		token: token,
	};
};

export const logout = (params) => {
	localStorage.removeItem('token');
	return {
		type: LOGOUT,
		params: params,
	};
};
