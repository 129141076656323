import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Categories.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../utils/error-handler';
import server from '../../utils/axios/api';
import nid from 'nid';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Input, Tag } from 'antd';

import NewCategoryModal from '../../components/Modal/NewCategoryModal/NewCategoryModal';

const { Text } = Typography;
const { Search } = Input;

const pagePath = 'categories';

const Categories = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [categories, setCategories] = useState([]);
	const [total, setTotal] = useState();
	const [modal, setModal] = useState(false);
	const [queryParams, setQueryParams] = useState({
		limit: '',
		offset: '',
		search: '',
	});
	const [loading, setLoading] = useState({
		search: false,
	});

	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails]);

	useEffect(() => {
		getCategories();
	}, [queryParams]);

	const getCategories = () => {
		server
			.get(`/categories?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${queryParams.search}`)
			.then((response) => {
				setCategories(response.data.rows);
				setTotal(response.data.count);
				props.setLoading(false);
				setLoading({ ...loading, search: false });
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleSearch = (string) => {
		setLoading({ ...loading, search: true });
		setQueryParams({
			...queryParams,
			search: string,
			limit: '',
			offset: '',
		});
	};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const handleClickCategory = (id) => {
		history.push('/category/' + id);
	};

	const renderParents = (record) => {
		return record.parents?.map((parent, idx) => {
			const parentCategory = categories.find((category) => category.key === parent);
			return (
				<Tag color={idx === record.parents.length - 1 ? 'green' : 'blue'} key={nid(24)}>
					{parentCategory?.name}
				</Tag>
			);
		});
	};

	const tableColumns = [
		{
			title: t('pages/categories/table/name'),
			key: nid(24),
			render: (record) => (
				<Text className={styles.tableLink} strong onClick={() => handleClickCategory(record.id)}>
					{record.name}
				</Text>
			),
		},
		{
			title: t('pages/categories/table/parents'),
			key: nid(24),
			render: (record) => <Text>{renderParents(record)}</Text>,
		},
	];

	return (
		<div className={styles.Categories}>
			<NewCategoryModal isModalVisible={modal} setIsModalVisible={setModal} getCategories={getCategories} />
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={t(`pages/${pageDetails.key}/title`)}
				subTitle={pageDetails.subtitle}
				extra={[
					<Search placeholder={t('search/placeholder')} allowClear enterButton onSearch={handleSearch} style={{ width: 300 }} loading={loading.search} />,
					<Button key={nid(24)} type='primary' onClick={() => setModal(true)}>
						{t('button/new-category')}
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card>
							<Table dataSource={categories} columns={tableColumns} pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize: 50 }} />
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Categories, pagePath));
