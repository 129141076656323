import React, { useState } from 'react';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import nid from 'nid'
import camelCase from 'camelcase';

import { Form, Input, Modal, Select } from 'antd';
const { Option } = Select;

const NewCategoryModal = (props) => {
	const [loading, setLoading] = useState(false);
	const [rootCategory, setRootCategory] = useState();
	const [subCategory, setSubCategory] = useState();

	const [form] = Form.useForm();

	const { t } = useTranslation(['common']);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		setRootCategory();
		setSubCategory();
		form.resetFields();
		props.setIsModalVisible(false);
	};

	const handleSelectCategoryRoot = (value) => {
		setRootCategory(value);
	};

	const handleSelectCategorySub = (value) => {
		setSubCategory(value);
	};

	const onFinish = (values) => {
		setLoading(true);

		server
			.post('/advertisement-categories', {
				key: camelCase(values.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")),
				name: values.name,
				isRoot: false,
				isSelectable: subCategory ? true : false,
				parents: subCategory ? [rootCategory, subCategory] : [rootCategory],
			})
			.then((response) => {
				setRootCategory();
				setSubCategory();
				form.resetFields();
				setLoading(false);
				props.setIsModalVisible(false);
				notification('success', t('notification/new-catefgory/success'), response.data.name);
				if (props.getCategories) {
					props.getCategories();
				}
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Modal
			title={t('modal/new-category/title')}
			visible={props.isModalVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			confirmLoading={loading}
			okText={t('modal/ok')}
			cancelText={t('modal/cancel')}>
			<Form name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout='vertical'>
				<Form.Item initialValue={rootCategory} label={t('modal/new-category/label/root')} name='rootCategory' rules={[{ required: true, message: 'Required field' }]}>
					<Select
						showSearch
						optionFilterProp='value'
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						onChange={handleSelectCategoryRoot}>
						{props.store.list.category
							.filter((category) => category.isRoot)
							.map((category) => {
								return (
									<Option key={nid(24)} value={category.key}>
										{category.name}
									</Option>
								);
							})}
					</Select>
				</Form.Item>
				<Form.Item initialValue={subCategory} label={t('modal/new-category/label/sub')} name='subCategory'>
					<Select
						showSearch
						optionFilterProp='value'
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						onChange={handleSelectCategorySub}>
						{props.store.list.category
							.filter((category) => !category.isRoot && !category.isSelectable && category.parents.includes(rootCategory))
							.map((category) => {
								return (
									<Option key={nid(24)} value={category.key}>
										{category.name}
									</Option>
								);
							})}
					</Select>
				</Form.Item>

				<Form.Item label={t('modal/new-category/label/name')} name='name' rules={[{ required: true, message: 'Required field' }]}>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
	};
};

export default connect(mapStateToProps)(NewCategoryModal);
