import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Customers.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../utils/error-handler';
import server from '../../utils/axios/api';
import nid from 'nid'

import { PageHeader, Button, Row, Col, Card, Table, Typography, Input } from 'antd';

const { Text } = Typography;
const { Search } = Input;

const pagePath = 'customers';

const Customers = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [customers, setCustomers] = useState([]);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: "",
		offset: "",
		search: ""
	});
	const [loading, setLoading] = useState({
		search: false
	})

	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails]);

	useEffect(() => {
		getCustomers();
	}, [queryParams]);

	const getCustomers = () => {
		server
			.get(`/customers?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${queryParams.search}`)
			.then((response) => {
				setCustomers(response.data.rows);
        setTotal(response.data.count)
				props.setLoading(false);
				setLoading({...loading, search: false});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleSearch = (string) => {
    setLoading({ ...loading, search: true });
    setQueryParams({
      ...queryParams,
      search: string,
      limit: "",
      offset: ""
    })
  }

  const handlePagination = (page, pageSize) => {
    setQueryParams({
      ...queryParams,
      limit: pageSize,
      offset: page === 1 ? "" : (page - 1) * pageSize,
    })
  }

	const handleClickCustomer = (key) => {
		history.push('/customer/' + key);
	};

	const tableColumns = [
		{
			title: t('pages/customers/table/company-name'),
			key: nid(24),
			render: (record) => (
				<Text className={styles.tableLink} strong onClick={() => handleClickCustomer(record.key)}>
					{record.companyName}
				</Text>
			),
		},
		{
			title: t('pages/customers/table/email'),
			key: nid(24),
			render: (record) => <Text>{record.user.email}</Text>,
		},
		{
			title: t('pages/customers/table/active-ads'),
			key: nid(24),
			render: (record) => <Text>-</Text>,
		},
	];

	return (
		<div className={styles.Customers}>
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={t(`pages/${pageDetails.key}/title`)}
				subTitle={pageDetails.subtitle}
				extra={[
					<Search placeholder={t("search/placeholder")} allowClear enterButton onSearch={handleSearch} style={{ width: 300 }} loading={loading.search}/>
				]}
			/>
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card>
							<Table dataSource={customers} columns={tableColumns} pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize: 50 }}/>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Customers, pagePath));
