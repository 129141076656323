import React, { useState } from 'react';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import { useTranslation } from 'react-i18next';

import { Form, Input, Modal, Typography, Row, Col } from 'antd';
const { TextArea } = Input;
const { Text } = Typography;

const RejectModal = (props) => {
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();
	const { t } = useTranslation(['common']);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.setIsModalVisible(false);
	};

	const onFinish = (values) => {
		setLoading(true);
		props.handleChangeStatus('rejected', values.reason);
		setLoading(false);
		form.resetFields();
		props.setIsModalVisible(false);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Modal
			title={t('modal/reject/title')}
			visible={props.isModalVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			okText={t('modal/send')}
			cancelText={t('modal/cancel')}
			confirmLoading={loading}>
			<Row gutter={[0, 32]}>
				<Col xs={24}>
					<Text>{t('modal/reject/description')}</Text>
				</Col>
				<Col xs={24}>
					<Form name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout='vertical'>
						<Form.Item label={t('modal/reject/reason')} name='reason' rules={[{ required: true, message: 'Required field' }]}>
							<TextArea rows={4} />
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Modal>
	);
};

export default RejectModal;
