import server from './axios/api';
import notification from './notification/notification';

const errorHandler = (error, notificationObject) => {
    server
        .post('/error', { statusCode: error.statusCode, message: error.message, stack: error.stack })
        .then((response) => {
            notification('error', notificationObject ? notificationObject.title : error.message, notificationObject ? notificationObject.description : error.statusCode)
        })
        .catch((err) => {
            console.log(err)
        });
};

export default errorHandler;
