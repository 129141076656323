import React from 'react';
import Helmet from 'react-helmet';

const Hoc = (Page, pagePath, pageDetails = {}) => (props) => {
	const setPageDetails = (page) => {
		pageDetails = {
			title: page.title,
		};
	};

	return (
		<>
			<Helmet>
				<title>{`${pageDetails.title} - ${props.store.system.appName}`}</title>
			</Helmet>
			<Page
				{...props} // Default props of/from wrapped Page component
				sendPageDetails={setPageDetails}
			/>
		</>
	);
};

export default Hoc;
