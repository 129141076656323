import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Advertisements.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../utils/error-handler';
import server from '../../utils/axios/api';
import nid from 'nid'
import Highlighter from 'react-highlight-words';
import { useDebounce } from 'use-debounce';
import moment from '../../utils/moment'

import { PageHeader, Button, Row, Col, Card, Table, Typography, Input, Tag, Badge } from 'antd';

const { Text, Paragraph, Title } = Typography;
const { Search } = Input;

const pagePath = 'advertisements';

const Advertisements = (props) => {
  const [pageDetails, setPageDetails] = useState({});
  const [advertisements, setAdvertisements] = useState([]);
  const [total, setTotal] = useState();
  const [queryParams, setQueryParams] = useState({
    limit: '',
    offset: '',
    search: '',
  });
  const [loading, setLoading] = useState({
    search: false,
  });
  const [searchString] = useDebounce(queryParams.search, 300);

  const { t } = useTranslation(['common', 'advertisementStatus', 'advertisementCategory']);
  const history = useHistory();

  useEffect(() => {
    props.setLoading(true);
  }, []);

  useEffect(() => {
    setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
  }, [props.store.system.pageDetails]);

  useEffect(() => {
    props.sendPageDetails(pageDetails);
  }, [pageDetails]);

  useEffect(() => {
    getAdvertisements();
  }, [queryParams]);

  const getAdvertisements = () => {
    server
      .get(`/advertisements?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${queryParams.search}`)
      .then((response) => {
        setAdvertisements(response.data.rows);
        setTotal(response.data.count);
        props.setLoading(false);
        setLoading({ ...loading, search: false });
        console.log(response.data.rows);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const handleSearch = (string) => {
    setLoading({ ...loading, search: true });
    setQueryParams({
      ...queryParams,
      search: string,
      limit: '',
      offset: '',
    });
  };

  const handlePagination = (page, pageSize) => {
    setQueryParams({
      ...queryParams,
      limit: pageSize,
      offset: page === 1 ? '' : (page - 1) * pageSize,
    });
  };

  const handleClickAdvertisement = (key) => {
    history.push('/advertisement/' + key);
  };

  const handleClickCustomer = (key) => {
    history.push('/customer/' + key);
  };

  const tableColumns = [
    {
      title: t('pages/advertisements/table/title'),
      width: '40%',
      key: nid(24),
      render: (record) => (
        <>
          <Text style={{ fontSize: "1.2rem" }} className={styles.tableLink} strong onClick={() => handleClickAdvertisement(record.key)}>
            <Highlighter searchWords={[searchString]} autoEscape={true} textToHighlight={record.title} />
          </Text>
          <Paragraph type='secondary'>
            <Highlighter searchWords={[searchString]} autoEscape={true} textToHighlight={`${record.description.substring(0, 200)}...`} />
          </Paragraph>
        </>
      ),
    },
    {
      title: t('pages/advertisements/table/status'),
      key: nid(24),
      render: (record) => (
        <Tag color={record.advertisementStatus.key === 'new' ? '#108ee9' : record.advertisementStatus.key === 'active' ? '#009a12' : record.advertisementStatus.key === 'rejected' ? '#ff0000' : null}>
          {t(`advertisementStatus:${record.advertisementStatus.key}`)}
        </Tag>
      ),
    },
    {
      title: t('pages/advertisements/table/categories'),
      key: nid(24),
      render: (record) => "category"
    },
    {
      title: t('pages/advertisements/table/customer-name'),
      key: nid(24),
      render: (record) => (
        <Text className={styles.tableLink} strong onClick={() => handleClickCustomer(record.subscription.customer.key)}>
          {record.subscription && record.subscription.customer.companyName}
        </Text>
      ),
    },
    {
      title: t('pages/advertisements/table/created'),
      key: nid(24),
      render: (record) => (
        <Text >
          {moment(record.createdAt).format('LLL')}
        </Text>
      ),
    },
  ];

  return (
    <div className={styles.Advertisements}>
      <PageHeader
        className={styles.pageHeader}
        onBack={() => window.history.back()}
        title={t(`pages/${pageDetails.key}/title`)}
        subTitle={pageDetails.subtitle}
        extra={[<Search placeholder={t('search/placeholder')} allowClear enterButton onSearch={handleSearch} style={{ width: 300 }} loading={loading.search} />]}
      />
      <div className={styles.pageBody}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card>
              <Table
                dataSource={advertisements}
                columns={tableColumns}
                pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize: 50 }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (params) => {
      dispatch(actionCreators.setLoading(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(hoc(Advertisements, pagePath));
