const cutText = (text, characters) => {
  if (text.length >= characters) {
    return `${text.substring(0, characters)}...`
  } else {
    return text
  }
}

export default cutText;



