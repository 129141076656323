import React from 'react';
import styles from './Auth.module.scss';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import serverAuth from '../../utils/axios/auth';
import errorHandler from '../../utils/error-handler';
import notification from '../../utils/notification/notification';

import { Row, Col, Form, Input, Button, Checkbox } from 'antd';

import logo from '../../assets/img/logo.png';

const Auth = (props) => {
	let history = useHistory();

	const onFinish = (values) => {
		serverAuth
			.post('/login', values)
			.then((response) => {
				let token = response.data.token;
				localStorage.setItem('token', token);
				serverAuth
					.get('/user', {
						headers: {
							Authorization: 'Bearer ' + token,
						},
					})
					.then((response) => {
						if (response.data.user.userRole.accessLevel < 30) {
							props.login(response.data, token);
							history.push('/');
							props.initializeApp(token);
							window.location.reload();
						} else {
							notification('warning', 'Nincs megfelelő jogosultságod a bejelentkezéshez');
						}
					})
					.catch((error) => {
						notification('success', 'Hibás felhasználónév vagy jelszó');
					});
			})
			.catch((error) => {
				notification('warning', 'Hibás felhasználónév vagy jelszó', 'Próbáld újra');
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className={styles.Auth}>
			<Row align='middle'>
				<Col span={16} className={styles.left}></Col>
				<Col span={8} className={styles.right}>
					<Row justify='center'>
						<Col className={styles.logoContainer}>
							<img src={logo} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col className={styles.loginContainer}>
							<Form
								name='basic'
								initialValues={{
									remember: true,
								}}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}>
								<Form.Item
									name='username'
									rules={[
										{
											required: true,
											message: 'Required field',
										},
									]}>
									<Input placeholder='Username' size='large' />
								</Form.Item>

								<Form.Item
									name='password'
									rules={[
										{
											required: true,
											message: 'Required field',
										},
									]}>
									<Input.Password placeholder='Password' size='large' />
								</Form.Item>
								<Form.Item name='remember' valuePropName='checked'>
									<Checkbox>Remember me for 2 weeks</Checkbox>
								</Form.Item>
								<Form.Item className={styles.submitButton}>
									<Button type='primary' htmlType='submit' size='large' block>
										Sign In
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (params, token) => {
			dispatch(actionCreators.login(params, token));
		},
		initializeApp: (params) => {
			dispatch(actionCreators.initializeApp(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Auth);
