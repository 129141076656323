import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import serverAuth from '../../utils/axios/auth';
import notification from '../../utils/notification/notification';

import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Auth from '../../pages/Auth/Auth';

const Guard = (props) => {
	let history = useHistory();
	let location = useLocation();

	useEffect(() => {
		props.setLoading(true);
		serverAuth
			.get('/user')
			.then((response) => {
				props.setUser(response.data, localStorage.getItem('token'));
				props.setLoading(false);
				history.push(location.pathname);
				props.initializeApp();
			})
			.catch((error) => {
				props.setLoading(false);
			});
	}, []);

	if (props.store.auth.isAuthenticated) {
		return <>{props.children}</>;
	} else {
		return (
			<ErrorBoundary>
				{props.store.ui.loading ? (
					<PageLoader store={props.store} />
				) : (
					<>
						<Switch>
							<Route exact path='/login' render={() => <Auth />} />
							<Route exact path='/forgotpassword' render={() => <Auth />} />
							<Route exact path='/resetpassword' render={() => <Auth />} />
							<Route render={() => <Redirect to='/login' />} />
						</Switch>
					</>
				)}
			</ErrorBoundary>
		);
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		initializeApp: (params) => {
			dispatch(actionCreators.initializeApp(params));
		},
		setUser: (params, token) => {
			dispatch(actionCreators.setUser(params, token));
		},
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Guard);
