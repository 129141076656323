import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/auth',
	headers: {
		post: {
			ContentType: 'application/json',
		},
		common: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	}
});

export default instance;
