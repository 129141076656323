import React, { useState } from 'react';
import styles from './Header.module.scss';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import notification from '../../utils/notification/notification';

import { Layout, Row, Col, Space, Drawer, Divider, Button, Form, Input, Typography, Avatar } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
const { Text } = Typography;

const { Header } = Layout;

const CustomHeader = (props) => {
	const [fullScreen, setFullScreen] = useState(false);
	const [drawerVisible, setDrawervisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const fullscreenHandler = () => {
		setFullScreen(!fullScreen);
		if (!fullScreen) {
			document.documentElement.requestFullscreen();
		} else {
			document.exitFullscreen();
		}
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.patch('/users/' + props.store.auth.user.id, { password: values.password })
			.then((response) => {
				form.resetFields();
				setLoading(false);
				notification('success', 'Password successfully updated');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const fullScreenIcon = fullScreen ? (
		<FullscreenExitOutlined className={styles.icon} onClick={fullscreenHandler} />
	) : (
		<FullscreenOutlined className={styles.icon} onClick={fullscreenHandler} />
	);

	let passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{12,}$/g; // Minimum 12 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number:

	return (
		<Header className={styles.Header}>
			<Drawer title='User Menu' placement='right' closable={true} visible={drawerVisible} key='right' onClose={() => setDrawervisible(false)}>
				<Row gutter={[16, 32]}>
					<Col span={24}>
						<Button block type='primary' onClick={() => props.logout()} size='large'>
							Logout
						</Button>
					</Col>
					<Col span={24}>
						<Text strong>Password</Text>
					</Col>
					<Col span={24}>
						<Form name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout='vertical'>
							<Form.Item
								label='New Password'
								name='password'
								rules={[{ required: true, message: 'Minimum 12 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number', pattern: passwordRegExp }]}>
								<Input type='password' />
							</Form.Item>
							<Form.Item
								label='Confirm New Password'
								name='passwordConfirm'
								rules={[
									{
										required: true,
										message: 'Requird field',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject('Passwords does not match');
										},
									}),
								]}>
								<Input type='password' />
							</Form.Item>
							<Form.Item>
								<Button type='default' htmlType='submit' block loading={loading}>
									Change Password
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Drawer>
			<Row>
				<Col span={12} className={styles.left}></Col>
				<Col span={12} className={styles.right}>
					<Space size='large' className={styles.iconGroup}>
						{fullScreenIcon}
						<Divider type='vertical' />
					</Space>
					<Space size='middle' className={styles.userDetails}>
						<div onClick={() => setDrawervisible(true)}>{`${props.store.auth.user.firstName} ${props.store.auth.user.lastName}`}</div>
						<div>
							<Avatar
								size={40}
								src={props.store.auth.user.photo ? props.store.system.filesPath + '/users/' + props.store.auth.user.photo : props.store.system.filesPath + '/users/default.png'}
								onClick={() => setDrawervisible(true)}
							/>
						</div>
					</Space>
				</Col>
			</Row>
		</Header>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: (params) => {
			dispatch(actionCreators.logout(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(CustomHeader);
