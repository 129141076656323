import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import hoc from '../../hoc/PageContainer';
import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';

import { PageHeader, Button, Row, Col, Card } from 'antd';

const pagePath = '/';

const Dashboard = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails])

	return (
		<div className={styles.Dashboard}>
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={t(`pages/${pageDetails.key}/title`)}
				subTitle={pageDetails.subtitle}
				extra={[
					<Button key='3'>Operation</Button>,
					<Button key='2'>Operation</Button>,
					<Button key='1' type='primary'>
						Operation
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card>Card content</Card>
					</Col>
					<Col span={8}>
						<Card title='Card title' bordered={false}>
							Card content
						</Card>
					</Col>
					<Col span={8}>
						<Card title='Card title' bordered={false}>
							Card content
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Dashboard, pagePath));

