import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Advertisement.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../../utils/error-handler';
import server from '../../../utils/axios/api';
import nid from 'nid'
import notification from '../../../utils/notification/notification';
import cutText from '../../../utils/cutText';
import { useJsApiLoader, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import locale from 'antd/es/date-picker/locale/hu_HU';
import moment from 'moment';

import NewCategoryModal from '../../../components/Modal/NewCategoryModal/NewCategoryModal';
import RejectModal from '../../../components/Modal/RejectModal/RejectModal';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Form, Input, InputNumber, Select, Modal, DatePicker, Tag, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;
const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const pagePath = 'advertisement';

const Advertisement = (props) => {
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [pageDetails, setPageDetails] = useState({});
	const [advertisement, setAdvertisement] = useState({ title: '', advertisementStatus: {} });
	const [files, setFiles] = useState([]);
	const [featured, setFeatured] = useState({});
	const [gallery, setGallery] = useState();
	const [listCountry, setListCountry] = useState([]);
	const [listCategories, setListCategories] = useState([]);
	const [rootCategory, setRootCategory] = useState();
	const [subCategory, setSubCategory] = useState();
	const [endCategory, setEndCategory] = useState();
	const [modal, setModal] = useState({
		newCategory: false,
		reject: false,
	});
	const [geoCode, setGeoCode] = useState({
		lat: 47.498387910218774,
		lng: 19.040501981571886,
	});
	const [loading, setLoading] = useState({
		save: false,
		active: false,
		rejected: false,
		inactive: false,
		approved: false,
	});
	const [disableActions, setDisableActions] = useState(false);

	const { t } = useTranslation(['common', 'country', 'advertisementStatus']);
	const params = useParams();
	const [form] = Form.useForm();
	const history = useHistory();

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	const googleMap = {
		containerStyle: {
			width: '100%',
			height: '400px',
		},
		center: geoCode,
	};

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails]);

	useEffect(() => {
		getAdvertisement();
		getListCountries();
		getCategories();
	}, []);

	useEffect(() => {
		const endCategoryData = listCategories.find((category) => category.id === endCategory);
		setRootCategory(endCategoryData && endCategoryData.parents[0]);
		setSubCategory(endCategoryData && endCategoryData.parents[1]);
	}, [endCategory, listCategories]);

	const getAdvertisement = () => {
		server
			.get('/advertisements/' + params.key)
			.then((response) => {
				setAdvertisement(response.data.data);
				setFiles(response.data.files);
				setFeatured(response.data.files.find((image) => image.Key.endsWith('featured.jpg')));
				setGallery(response.data.files.filter((image) => !image.Key.endsWith('featured.jpg')));
				setGeoCode({
					lat: response.data.data.geoLocationLat,
					lng: response.data.data.geoLocationLng,
				});
				setEndCategory(response.data.data.advertisementCategoryId);
				props.setLoading(false);
				form.resetFields();
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getListCountries = () => {
		server
			.get('/list/countries')
			.then((response) => {
				setListCountry(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getCategories = () => {
		server
			.get(`/list/categories`)
			.then((response) => {
				setListCategories(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleSelectCategoryRoot = (value) => {
		setRootCategory(value);
	};

	const handleSelectCategorySub = (value) => {
		setSubCategory(value);
	};

	const handleSelectCategoryEnd = (value) => {
		setEndCategory(value);
	};

	const handleClickUpdateAdvertisement = () => {
		form.submit();
	};

	const handleClickDeleteAdvertisement = () => {
		confirm({
			title: t('pages/advertisement/modal/confirm/delete'),
			icon: <ExclamationCircleOutlined />,
			content: advertisement.title,
			okText: t('modal/ok'),
			cancelText: t('modal/cancel'),
			onOk() {
				server
					.delete('/advertisements/' + params.key)
					.then((response) => {
						history.push('/advertisements');
						notification('success', t('pages/advertisement/notification/delete'), advertisement.title);
					})
					.catch((error) => {
						setLoading({ ...loading, save: false });
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleChangeStatus = (newStatus, modifications) => {
		setLoading({ ...loading, [newStatus]: true });
		setDisableActions(true);
		server
			.post(`/worker/advertisements/status/${advertisement.id}`, {
				newStatus,
				modifications,
			})
			.then((response) => {
				getAdvertisement();
				setLoading({ ...loading, [newStatus]: false });
				setDisableActions(false);
				notification('success', t('pages/advertisement/notification/change-status'), advertisement.title);
			})
			.catch((error) => {
				setLoading({ ...loading, save: false });
				errorHandler(error);
			});
	};

	const handleClickRejected = () => {
		setModal({ ...modal, reject: true });
	};

	const handleClickApproved = () => {
		confirm({
			title: t('pages/advertisement/modal/confirm/approve/title'),
			icon: <ExclamationCircleOutlined />,
			content: t('pages/advertisement/modal/confirm/approve/description'),
			okText: t('modal/ok'),
			cancelText: t('modal/cancel'),
			onOk() {
				handleChangeStatus('approved');
			},
			onCancel() {},
		});
	};

	const handleFormSubmit = (values) => {
		const country = listCountry.find((country) => country.id === values.countryId);
		setLoading({ ...loading, save: true });
		setDisableActions(true);
		server
			.patch('/advertisements/' + params.key, {
				...values,
				locationCountry: listCountry.find((country) => country.id === values.countryId).name,
				...(endCategory
					? {
							advertisementCategoryId: endCategory,
					  }
					: {}),
			})
			.then((response) => {
				getAdvertisement();
				setLoading({ ...loading, save: false });
				setDisableActions(false);
				notification('success', t('pages/advertisement/notification/save'), advertisement.title);
			})
			.catch((error) => {
				setLoading({ ...loading, save: false });
				errorHandler(error);
			});
	};

	const handleFormSubmitFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const handleFormRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	return (
		<div className={styles.Advertisement}>
			<NewCategoryModal isModalVisible={modal.newCategory} setIsModalVisible={(input) => setModal({ ...modal, newCategory: input })} getCategories={getCategories} />
			<RejectModal
				isModalVisible={modal.reject}
				setIsModalVisible={(input) => setModal({ ...modal, reject: input })}
				getAdvertisement={getAdvertisement}
				handleChangeStatus={handleChangeStatus}
			/>
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Tag
							color={
								advertisement.advertisementStatus.key === 'new'
									? '#108ee9'
									: advertisement.advertisementStatus.key === 'active'
									? '#009a12'
									: advertisement.advertisementStatus.key === 'rejected'
									? '#ff0000'
									: null
							}>
							{t(`advertisementStatus:${advertisement.advertisementStatus.key}`)}
						</Tag>
						{cutText(advertisement.title, 200)}
					</div>
				}
				extra={[
					<Text type='secondary' copyable={{ tooltips: [t('copyable/before'), t('copyable/after')] }}>
						{advertisement.key}
					</Text>,
					<Button
						key={nid(24)}
						onClick={() => handleChangeStatus('inactive')}
						loading={loading.inactive}
						disabled={disableActions || advertisement.advertisementStatus.key === 'inactive'}>
						{t('button/inactivate')}
					</Button>,
					<Button key={nid(24)} onClick={handleClickRejected} loading={loading.rejected} disabled={disableActions || advertisement.advertisementStatus.key === 'rejected'}>
						{t('button/reject')}
					</Button>,
					<Button key={nid(24)} onClick={handleClickApproved} loading={loading.approved} disabled={disableActions || advertisement.advertisementStatus.key === 'approved'}>
						{t('button/approve')}
					</Button>,
					<Button key={nid(24)} danger onClick={handleClickDeleteAdvertisement} loading={loading.save} disabled={disableActions}>
						{t('button/delete')}
					</Button>,
					<Button key={nid(24)} type='primary' onClick={handleClickUpdateAdvertisement} loading={loading.save} disabled={disableActions}>
						{t('button/save')}
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Form
					key={nid(24)}
					form={form}
					layout='vertical'
					initialValues={{
						requiredMark,
						/* ...advertisement, */
					}}
					onValuesChange={handleFormRequiredTypeChange}
					requiredMark={requiredMark}
					onFinish={handleFormSubmit}
					onFinishFailed={handleFormSubmitFailed}>
					<Row gutter={[16, 16]}>
						<Col span={14}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Card title={t('pages/advertisement/card/basic/title')}>
										<Row>
											<Col span={24}>
												<Form.Item
													initialValue={advertisement.title}
													label={t('pages/advertisement/form/title/label')}
													name='title'
													rules={[{ required: true, message: t('form/required/default') }]}>
													<Input />
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													initialValue={advertisement.description}
													label={t('pages/advertisement/form/description/label')}
													name='description'
													rules={[{ required: true, message: t('form/required/default') }]}>
													<TextArea rows='15' />
												</Form.Item>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col span={24}>
									<Card title={t('pages/advertisement/card/details/title')}>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Form.Item
													initialValue={advertisement.price}
													label={t('pages/advertisement/form/price/label')}
													name='price'
													rules={[{ required: true, message: t('form/required/default') }]}>
													<InputNumber step='0.01' style={{ width: '100%' }} />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={t('pages/advertisement/form/born/label')} name='born'>
													<DatePicker
														locale={locale}
														defaultValue={typeof advertisement.born === 'undefined' ? moment() : moment(advertisement.born, 'YYYY/MM/DD - HH:mm')}
														style={{ width: '100%' }}
													/>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item initialValue={advertisement.contactName} label={t('pages/advertisement/form/contact-name/label')} name='contactName'>
													<Input />
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item initialValue={advertisement.contactEmail} label={t('pages/advertisement/form/contact-email/label')} name='contactEmail'>
													<Input />
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item initialValue={advertisement.contactPhone} label={t('pages/advertisement/form/contact-phone/label')} name='contactPhone'>
													<Input />
												</Form.Item>
											</Col>
										</Row>
									</Card>
								</Col>

								<Col span={24}>
									<Card title={t('pages/advertisement/card/category/title')}>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Row gutter={[16, 16]}>
													<Col span={24}>
														<Text>{t('pages/advertisement/card/category/notes')}: </Text>
														<Text type='danger' strong>
															{advertisement.categoryNotes || '-'}
														</Text>
													</Col>
													<Col span={24}>
														<Button type='primary' onClick={() => setModal({ ...modal, newCategory: true })}>
															{t('button/new-category')}
														</Button>
													</Col>
												</Row>
											</Col>
											<Col span={12}>
												<Row gutter={[16, 16]}>
													<Col span={24}>
														<Form.Item initialValue={rootCategory} label={t('pages/advertisement/form/category/label/root')} name='rootCategory'>
															<Select
																showSearch
																optionFilterProp='value'
																filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
																onChange={handleSelectCategoryRoot}>
																{listCategories
																	.filter((category) => category.isRoot)
																	.map((category) => {
																		return (
																			<Option key={nid(24)} value={category.key}>
																				{category.name}
																			</Option>
																		);
																	})}
															</Select>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Form.Item initialValue={subCategory} label={t('pages/advertisement/form/category/label/sub')} name='subCategory'>
															<Select
																showSearch
																optionFilterProp='value'
																filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
																onChange={handleSelectCategorySub}>
																{listCategories
																	.filter((category) => !category.isRoot && !category.isSelectable && category.parents.includes(rootCategory))
																	.map((category) => {
																		return (
																			<Option key={nid(24)} value={category.key}>
																				{category.name}
																			</Option>
																		);
																	})}
															</Select>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Form.Item initialValue={endCategory} label={t('pages/advertisement/form/category/label/end')} name='endCategory'>
															<Select
																showSearch
																optionFilterProp='value'
																filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
																onChange={handleSelectCategoryEnd}>
																{listCategories
																	.filter((category) => category.isSelectable && category.parents.includes(subCategory))
																	.map((category) => {
																		return (
																			<Option key={nid(24)} value={category.id}>
																				{category.name}
																			</Option>
																		);
																	})}
															</Select>
														</Form.Item>
													</Col>
												</Row>
											</Col>
										</Row>
									</Card>
								</Col>

								<Col span={24}>
									<Card title='Térkép'>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Form.Item
													initialValue={advertisement.countryId}
													label={t('pages/advertisement/form/country/label')}
													name='countryId'
													rules={[{ required: true, message: t('form/required/default') }]}>
													<Select showSearch optionFilterProp='value' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
														{listCountry.map((country) => {
															return (
																<Option key={nid(24)} value={country.id}>
																	{t(`country:${country.key}`)}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													initialValue={advertisement.locationCity}
													label={t('pages/advertisement/form/city/label')}
													name='locationCity'
													rules={[{ required: true, message: t('form/required/default') }]}>
													<Input />
												</Form.Item>
											</Col>
											<Col span={24}>
												{isLoaded && (
													<GoogleMap mapContainerStyle={googleMap.containerStyle} center={googleMap.center} zoom={10}>
														{/* Child components, such as markers, info windows, etc. */}
														<>
															<Marker position={googleMap.center} />
														</>
													</GoogleMap>
												)}
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col span={10}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Card title='Fotók'>
										<img src={`${process.env.REACT_APP_PUBLIC_S3}/${featured.Key}`} alt='featured' style={{ width: '100%', border: '5px solid orange', marginBottom: '32px' }} />
										{gallery &&
											gallery.map((image) => {
												return <img src={`${process.env.REACT_APP_PUBLIC_S3}/${image.Key}`} alt={image.Key} style={{ width: '100%', marginTop: '16px' }} />;
											})}
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Advertisement, pagePath));
