import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Customer.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../../utils/error-handler';
import server from '../../../utils/axios/api';
import nid from 'nid'
import notification from '../../../utils/notification/notification';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Form, Input, Select, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const pagePath = 'customer';

const Customer = (props) => {
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [pageDetails, setPageDetails] = useState({});
	const [customer, setCustomer] = useState({ user: {} });
	const [listCountry, setListCountry] = useState([]);
	const [loading, setLoading] = useState({
		save: false,
	});
	const [state, setState] = useState({
		company: false,
	});

	const { t } = useTranslation(['common', 'country']);
	const params = useParams();
	const [form] = Form.useForm();
	const history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails]);

	useEffect(() => {
		getCustomer();
	}, []);

	useEffect(() => {
		server
			.get('/list/countries')
			.then((response) => {
				setListCountry(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const getCustomer = () => {
		server
			.get('/customers/' + params.key)
			.then((response) => {
				setCustomer(response.data);
				setState({ ...state, isCompany: response.data.type === 'company' });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleClickUpdateCustomer = () => {
		form.submit();
	};

	const handleClickDeleteCustomer = () => {
		confirm({
			title: t('pages/customer/modal/confirm/delete'),
			icon: <ExclamationCircleOutlined />,
			content: customer.companyName,
			okText: t('modal/ok'),
			cancelText: t('modal/cancel'),
			onOk() {
				server
					.delete('/customers/' + params.key)
					.then((response) => {
						history.push("/customers")
						notification('success', t('pages/customer/notification/delete'), customer.companyName);
					})
					.catch((error) => {
						setLoading({ ...loading, save: false });
						errorHandler(error);
					});
			},
			onCancel() {

			},
		});
	};

	const handleFormSubmit = (values) => {
		setLoading({ ...loading, save: true });
		server
			.patch('/customers/' + params.key, {
				...values,
				billingCountryKey: listCountry.find((country) => country.id === values.billingCountry).key,
				email: customer.user.email
			})
			.then((response) => {
				getCustomer();
				setLoading({ ...loading, save: false });
				notification('success', t('pages/customer/notification/save'), customer.companyName);
			})
			.catch((error) => {
				setLoading({ ...loading, save: false });
				errorHandler(error);
			});
	};

	const handleFormSubmitFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const handleFormRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	return (
		<div className={styles.Customer}>
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={customer.companyName}
				subTitle={customer.user.email}
				extra={[
					<Text type='secondary' copyable={{ tooltips: [t("copyable/before"), t("copyable/after")] }}>{customer.key}</Text>,
					<Button key={nid(24)} danger onClick={handleClickDeleteCustomer} loading={loading.save}>
						{t('button/delete')}
					</Button>,
					<Button key={nid(24)} type='primary' onClick={handleClickUpdateCustomer} loading={loading.save}>
						{t('button/save')}
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Form
					key={nid(24)}
					form={form}
					layout='vertical'
					initialValues={{
						requiredMark,
						...customer,
					}}
					onValuesChange={handleFormRequiredTypeChange}
					requiredMark={requiredMark}
					onFinish={handleFormSubmit}
					onFinishFailed={handleFormSubmitFailed}>
					<Row gutter={[16, 16]}>
						<Col span={8}>
							<Card title={t('pages/customer/card/basic/title')}>
								<Form.Item label={t('pages/customer/form/company-name/label')} name='companyName' rules={[{ required: true, message: t('form/required/default') }]}>
									<Input />
								</Form.Item>
								<Form.Item label={t('pages/customer/form/type/label')} name='type' rules={[{ required: true, message: t('form/required/default') }]}>
									<Select onSelect={(value) => (value === 'company' ? setState({ ...state, isCompany: true }) : setState({ ...state, isCompany: false }))}>
										<Option key={nid(24)} value={'individual'}>
											{t('pages/customer/form/type/individual')}
										</Option>
										<Option key={nid(24)} value={'company'}>
											{t('pages/customer/form/type/company')}
										</Option>
									</Select>
								</Form.Item>
							</Card>
							<Card title={t('pages/customer/card/billing/title')} style={{ marginTop: '16px' }}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<Form.Item
											label={t('pages/customer/form/billing-address-line1/label')}
											name='billingAddressLine1'
											rules={[{ required: true, message: t('form/required/default') }]}>
											<Input />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item name='billingAddressLine2'>
											<Input />
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={t('pages/customer/form/billing-postal-code/label')}
											name='billingPostalCode'
											rules={[{ required: true, message: t('form/required/default') }]}>
											<Input />
										</Form.Item>
									</Col>
									<Col span={16}>
										<Form.Item label={t('pages/customer/form/billing-city/label')} name='billingCity' rules={[{ required: true, message: t('form/required/default') }]}>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label={t('pages/customer/form/billing-country/label')} name='billingCountry' rules={[{ required: true, message: t('form/required/default') }]}>
											<Select showSearch optionFilterProp='value' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCountry.map((country) => {
													return (
														<Option key={nid(24)} value={country.id}>
															{t(`country:${country.key}`)}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label={t('pages/customer/form/billing-state/label')} name='billingState'>
											<Input />
										</Form.Item>
									</Col>
									{state.isCompany && (
										<Col span={12}>
											<Form.Item
												label={t('pages/customer/form/billing-vat/label')}
												name='billingVatId'
												rules={[{ required: state.isCompany, message: t('form/required/default') }]}>
												<Input />
											</Form.Item>
										</Col>
									)}
								</Row>
							</Card>
						</Col>
						<Col span={12}>
							<Card title={t('pages/customer/card/subscriptions/title')}>
								123
							</Card>
							<Card title={t('pages/customer/card/advertisements/title')} style={{ marginTop: '16px' }}>
								123
							</Card>
						</Col>
						<Col span={4}>
							<Card title={t('pages/customer/card/right/title')}></Card>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Customer, pagePath));
