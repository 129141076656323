import React from 'react';
import styles from './Sidebar.module.scss';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoImg from '../../assets/img/logo_img.png';
import logoTextWhite from '../../assets/img/logo_text_white.png';
import logoTextBlack from '../../assets/img/logo_text_black.png';

import { Layout, Menu, Badge } from 'antd';
import { DesktopOutlined, PieChartOutlined, AppstoreAddOutlined, FileOutlined, TeamOutlined } from '@ant-design/icons';

import menuData from '../../data/menu.json';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
	const { t } = useTranslation();
	let history = useHistory();
	let location = useLocation();

	let logoText =
		props.store.ui.theme === 'light' ? (
			<img src={logoTextBlack} className={styles.logoText} alt={props.store.system.appName} />
		) : (
			<img src={logoTextWhite} className={styles.logoText} alt={props.store.system.appName} />
		);

	const onCollapse = () => {
		props.setSidebarState({ collapsed: !props.store.sidebar.collapsed });
	};

	const menuItemClickHandler = ({ key }) => {
		history.push(key);
	};

	const menuItems = menuData.modules.map((item) => {
		const icon = () => {
			switch (item.icon) {
				case 'DesktopOutlined':
					return <DesktopOutlined />;
				case 'PieChartOutlined':
					return <PieChartOutlined />;
				case 'AppstoreAddOutlined':
					return <AppstoreAddOutlined />;
				case 'FileOutlined':
					return <FileOutlined />;
				case 'TeamOutlined':
					return <TeamOutlined />;
				default:
					return <DesktopOutlined />;
			}
		};

		if (item.hasChildren) {
			const menuDataFiltered = menuData.items.filter((filteredItem) => filteredItem.parent === item.key);

			return (
				<SubMenu key={item.key} icon={icon()} title={item.title}>
					{menuDataFiltered.map((subItem) => {
						if (subItem.children) {
							return (
								<SubMenu key={subItem.key} title={subItem.title}>
									{subItem.children.map((childItem) => {
										return (
											<Menu.Item key={childItem.key}>
												{t(`menu${childItem.key}`)}
												{childItem.inactive && (
													<Badge count={'v2.1'} style={{ backgroundColor: '#555555', color: '#999999', marginLeft: '10px', borderColor: '#555555' }} size='small' />
												)}
											</Menu.Item>
										);
									})}
								</SubMenu>
							);
						}

						return (
							<Menu.Item key={subItem.key}>
								{subItem.title}{' '}
								{subItem.inactive && <Badge count={'v2.1'} style={{ backgroundColor: '#555555', color: '#999999', marginLeft: '10px', borderColor: '#555555' }} size='small' />}
							</Menu.Item>
						);
					})}
				</SubMenu>
			);
		}

		return (
			<Menu.Item key={item.key} icon={icon()}>
				{t(`menu${item.key}`)}
				{item.inactive && <Badge count={'v2.1'} style={{ backgroundColor: '#555555', color: '#999999', marginLeft: '10px', borderColor: '#555555' }} size='small' />}
			</Menu.Item>
		);
	});

	return (
		<Sider className={styles.Sider} theme={props.store.ui.theme} width={240} collapsible collapsed={props.store.sidebar.collapsed} onCollapse={onCollapse}>
			<div className={styles.logoContainer}>
				<div>
					<Link to='/'>
						<img src={logoImg} className={styles.logoImg} style={{ paddingLeft: props.store.sidebar.collapsed && '9px' }} alt={props.store.system.appName} />
					</Link>
				</div>
				<div>
					<Link to='/'>{logoText}</Link>
				</div>
			</div>
			<Menu
				className={styles.menu}
				theme={props.store.ui.theme}
				defaultSelectedKeys={[location.pathname === '/' ? location.pathname : location.pathname.substring(1)]}
				mode='inline'
				onClick={menuItemClickHandler}>
				{menuItems}
			</Menu>
		</Sider>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSidebarState: (params) => {
			dispatch(actionCreators.setSidebarState(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Sidebar);
