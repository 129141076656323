import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Category.module.scss';
import { useTranslation } from 'react-i18next';
import errorHandler from '../../../utils/error-handler';
import server from '../../../utils/axios/api';
import nid from 'nid'
import notification from '../../../utils/notification/notification';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Form, Input, Select, Modal } from 'antd';

const pagePath = 'category';

const Category = (props) => {
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [pageDetails, setPageDetails] = useState({});
	const [category, setCategory] = useState({});
	const [loading, setLoading] = useState({
		save: false,
	});

	const { t } = useTranslation(['common']);
	const params = useParams();
	const [form] = Form.useForm();
	const history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		props.sendPageDetails(pageDetails);
	}, [pageDetails]);

	useEffect(() => {
		getCategory();
	}, []);

	const getCategory = () => {
		server
			.get('/categories/' + params.id)
			.then((response) => {
				setCategory(response.data);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleClickUpdateCategory = () => {
		form.submit();
	};

	const handleFormSubmit = (values) => {
		setLoading({ ...loading, save: true });
		server
			.patch('/categories/' + params.id, values)
			.then((response) => {
				getCategory();
				setLoading({ ...loading, save: false });
				notification('success', t('pages/category/notification/save'), category.name);
			})
			.catch((error) => {
				setLoading({ ...loading, save: false });
				errorHandler(error);
			});
	};

	const handleFormSubmitFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const handleFormRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	return (
		<div className={styles.Category}>
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={category.name}
				extra={[
					<Button key={nid(24)} type='primary' onClick={handleClickUpdateCategory} loading={loading.save}>
						{t('button/save')}
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Form
					key={nid(24)}
					form={form}
					layout='vertical'
					initialValues={{
						requiredMark,
						...category,
					}}
					onValuesChange={handleFormRequiredTypeChange}
					requiredMark={requiredMark}
					onFinish={handleFormSubmit}
					onFinishFailed={handleFormSubmitFailed}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card>
								<Form.Item label={t('pages/category/form/name/label')} name='name' rules={[{ required: true, message: t('form/required/default') }]}>
									<Input />
								</Form.Item>
							</Card>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.setLoading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Category, pagePath));
