import axiosRetry from 'axios-retry';
import { setup } from 'axios-cache-adapter';
import errorHandler from '../error-handler';
import store from '../../store/store';

const instance = setup({
	baseURL: process.env.REACT_APP_API_URL + '/api',
	headers: {
		post: {
			ContentType: 'application/json',
		},
		common: {
			Authorization: 'Bearer ' + localStorage.getItem('token') || store.getState().general.auth.token,
		},
	},
	cache: {
		maxAge: 5 * 60 * 1000,
		exclude: {
			methods: ['get', 'post', 'put', 'patch', 'delete'],
		},
	},
});

async function defaultInvalidate(config) {
	const method = config.method.toLowerCase();

	if (method == 'post' || method == 'put' || method == 'patch') {
		instance.cache.removeItem(config.baseURL + config.url).then((res) => {});
		/*
		instance.cache.clear().then((res) => {
			console.log('cache cleared');
		});
		*/
	}

	if (method == 'delete') {
		instance.cache.clear().then((res) => {});
	}
}

// Add a request interceptor
instance.interceptors.request.use(
	function (config) {
		if (config.method != 'get') {
			defaultInvalidate(config);
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

/* axiosRetry(instance, {
	retries: 10,
	retryDelay: (retryCount) => {
		errorHandler({ message: `retry attempt: ${retryCount}` });
		return retryCount * 200; // time interval between retries
	},
}); */

export default instance;
