import React from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import styles from './PageLoader.module.scss';

const PageLoader = (props) => {
	return (
		<div className={props.store.ui.loading ? styles.PageLoader : styles.PageLoader + ' ' + styles.hidden}>
			<LoopCircleLoading color='#0063cc' />
		</div>
	);
};

export default PageLoader;
